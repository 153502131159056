import axios from "axios";
import { urls, injectUrlValues } from "logic/apiutils";

const load = (apiToken, projectid) => {
  const config = {
    method : 'get',
    url    : injectUrlValues(urls.users, {
      projectid,
      // ^ see issue #448 Webapp api users should not use projectid
      // the projectid here is not needed and should be removed because misleading.
      // Here + in operations + same for all other endpoints here with same problem
    }),
    headers: {
      Authorization: 'Bearer ' + apiToken,
    },
    data   : undefined,
  };

  return axios(config);
};

const add = (apiToken, projectid, user) => {
  const config = {
    method : 'post',
    url    : injectUrlValues(urls.users, {
      projectid,
    }),
    headers: {
      Authorization: 'Bearer ' + apiToken,
    },
    data   : {
      format: 0,
      user,
    },
  };

  return axios(config);
};

const remove = (apiToken, projectid, userid) => {
  const config = {
    method : 'delete',
    url    : injectUrlValues(urls.user, {
      projectid,
      userid,
    }),
    headers: {
      Authorization: 'Bearer ' + apiToken,
    },
    data   : undefined,
  };

  return axios(config);
};

const update = (apiToken, projectid, userid, user) => {
  const config = {
    method : 'put',
    url    : injectUrlValues(urls.user, {
      projectid,
      userid,
    }),
    headers: {
      Authorization: 'Bearer ' + apiToken,
    },
    data   : {
      format: 0,
      user
    },
  };

  return axios(config);
};

const get = (apiToken, projectid, userid) => {
  const config = {
    method : 'get',
    url    : injectUrlValues(urls.user, {
      projectid,
      userid,
    }),
    headers: {
      Authorization: 'Bearer ' + apiToken,
    },
    data   : undefined,
  };

  return axios(config);
};

export default {
  load,
  add,
  remove,
  update,
  get,
};
