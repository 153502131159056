import React from "react";
import ReactDOM from "react-dom";
import { Provider as ReduxProvider } from "react-redux";

import configureStore from "./state/store";
import App from "./views/App";
import "./views/resources/font-awesome/css/font-awesome.css";
import "./views/resources/fonts/index.css";
import './i18n';

export const reduxStore = configureStore(window.REDUX_INITIAL_DATA);

const renderApp = Component => {
  ReactDOM.render(
    <ReduxProvider store={reduxStore}>
      <Component/>
    </ReduxProvider>,
    document.getElementById("root")
  );
};

renderApp(App);

// Hot reload in develop env:
if (module.hot) module.hot.accept('./views/App', () => renderApp(App))