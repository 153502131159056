import React from 'react';
import PropTypes from 'prop-types';
import FontAwesome from 'react-fontawesome';

import { Alert } from 'react-bootstrap';


class Oopsy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alertVisible: true,
    };
  }

  render() {
    const { error } = this.props.apicomstatus;
    const { alertVisible } = this.state;

    return error && alertVisible ? (
      <Alert
        className="hover"
        bsStyle="warning"
        onDismiss={() => {this.setState({ alertVisible: false })}}
      >
        <FontAwesome name="exclamation-circle"/>
        {' '}
        {error}
      </Alert>
    ) : null;
  }
}

Oopsy.propTypes = {
  apicomstatus: PropTypes.shape({
    error: PropTypes.string,
    //errors: PropTypes.arrayOf(PropTypes.string),   // todo try this approach?
  }).isRequired,
}

export default Oopsy

