import { VALID } from './constants';


export const getUserValid = (user, isNewUser=false) => {
  const valid = {
    username    : (typeof user.username) === "string" && VALID.USERNAME_PREGMATCH.test(user.username),
    email       : (typeof user.email) === "string" && VALID.EMAIL_PREGMATCH.test(user.email),
    password    : (!isNewUser && !user.password) || VALID.PASSWORD_PREGMATCH.test(user.password),
    password2   : (!isNewUser && !user.password) || (user.password===user.password2),
    redirecturl : (user.redirecturl==='') || VALID.REDIRECTURL_PREGMATCH.test(user.redirecturl),
    locale_language : (typeof user.locale_language) === "string",
  };
  return {
    ...valid,
    everything: !!(
      valid.username &&
      valid.email &&
      valid.password &&
      valid.password2 &&
      valid.redirecturl &&
      valid.locale_language
    )
  }
}

export const getResetPasswordValid = (obj) => {
  const valid = {
    resettoken  : (typeof obj.resettoken) === "string",
    password1   : VALID.PASSWORD_PREGMATCH.test(obj.password1),
    password2   : !obj.password1 || (obj.password1===obj.password2),
  };
  return {
    ...valid,
    everything: !!(
      valid.resettoken &&
      valid.password1 &&
      valid.password2
    )
  }
}

export const getUserStripped = (item) => (
  {
    // Leave out any irrelevant/derived (non-writable and non-id) field
    ...(item.id ? { id: item.id } : {}),
    username         : item.username,
    enabled          : item.enabled,
    email            : item.email,
    ...(item.password  ? { password : item.password  } : {}),      // optional
    ...(item.password2 ? { password2: item.password2 } : {}),      // optional
    projectid        : item.projectid,
    defaultprojectid : item.defaultprojectid,
    redirecturl      : item.redirecturl,
    locale_language  : item.locale_language,
  }
);