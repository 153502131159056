import types from "./types";

export const usersReset = () => ({
  type   : types.USERS_RESET,
  payload: {},
});

export const usersLoadPending = projectid => ({
  type   : types.USERS_LOAD_PENDING,
  payload: {
    projectid,
  }
});

export const usersLoadSuccess = (projectid, users) => ({
  type   : types.USERS_LOAD_SUCCESS,
  payload: {
    projectid,
    users,
  },
});

export const usersLoadFailure = (projectid, error) => ({
  type   : types.USERS_LOAD_FAILURE,
  payload: {
    projectid,
    error,
  },
});

export const usersAddPending = (projectid, user) => ({
  type   : types.USERS_ADD_PENDING,
  payload: {
    projectid,
    user,
  }
});

export const usersAddSuccess = (projectid, user) => ({
  type   : types.USERS_ADD_SUCCESS,
  payload: {
    projectid,
    user,
  },
});

export const usersAddFailure = (projectid, user, error) => ({
  type   : types.USERS_ADD_FAILURE,
  payload: {
    projectid,
    user,
    error,
  },
});

export const usersRemovePending = (projectid, userid) => ({
  type   : types.USERS_REMOVE_PENDING,
  payload: {
    projectid,
    userid,
  }
});

export const usersRemoveSuccess = (projectid, userid) => ({
  type   : types.USERS_REMOVE_SUCCESS,
  payload: {
    projectid,
    userid,
  },
});

export const usersRemoveFailure = (projectid, userid, error) => ({
  type   : types.USERS_REMOVE_FAILURE,
  payload: {
    projectid,
    userid,
    error,
  },
});

export const usersUpdatePending = (projectid, userid, user) => ({
  type   : types.USERS_UPDATE_PENDING,
  payload: {
    projectid,
    userid,
    user,
  }
});

export const usersUpdateSuccess = (projectid, userid, user) => ({
  type   : types.USERS_UPDATE_SUCCESS,
  payload: {
    projectid,
    userid,
    user,
  },
});

export const usersUpdateFailure = (projectid, userid, user, error) => ({
  type   : types.USERS_UPDATE_FAILURE,
  payload: {
    projectid,
    userid,
    user,
    error,
  },
});

export const usersGetPending = (projectid, userid) => ({
  type   : types.USERS_GET_PENDING,
  payload: {
    projectid,
    userid,
  }
});

export const usersGetSuccess = (projectid, userid, user) => ({
  type   : types.USERS_GET_SUCCESS,
  payload: {
    projectid,
    userid,
    user,
  },
});

export const usersGetFailure = (projectid, userid, error) => ({
  type   : types.USERS_GET_FAILURE,
  payload: {
    projectid,
    userid,
    error,
  },
});

export default {
  usersReset,
  usersLoadPending,
  usersLoadSuccess,
  usersLoadFailure,
  usersAddPending,
  usersAddSuccess,
  usersAddFailure,
  usersRemovePending,
  usersRemoveSuccess,
  usersRemoveFailure,
  usersUpdatePending,
  usersUpdateSuccess,
  usersUpdateFailure,
  usersGetPending,
  usersGetSuccess,
  usersGetFailure,
};
