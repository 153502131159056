import { connect } from "react-redux";
import { sessionOperations, sessionSelectors } from "state/ducks/session";
import SigninPage from "../components/auth/SigninPage";

const mapStateToProps = state => ({
  user         : sessionSelectors.getUser(state),
  authenticated: sessionSelectors.getAuthenticated(state),
  apicomstatus : sessionSelectors.getApicomstatus(state),
  projectclaims: sessionSelectors.getProjectClaims(state, sessionSelectors.getCurrentProjectid(state)),
});

const mapDispatchToProps = {
  onSignin         : sessionOperations.sessionSignin,
  onSignout        : sessionOperations.sessionSignout,
  onPasswordForgot : sessionOperations.sessionPasswordForgot,
};

const ConnectedSigninPage = connect(mapStateToProps, mapDispatchToProps)(SigninPage);

export default ConnectedSigninPage;
