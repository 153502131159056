import actions from "./actions";
import actionsUsers from "../users/actions";
import api from "./api";
import sessionSelectors from "./selectors";
import { AUTOREFRESH } from "./constants";
import { getErrorMessage } from "logic/apiutils";
import i18n from 'i18n.js'

const applyUserPref = (user) => {
  // Apply UI language
  let language = user.locale_language;
  if (language==='key') language='cimode';
  if (language && (language !== i18n.language)) {
    i18n.changeLanguage(language);
  }
}

// Local vars

let _autorefreshtimeout = null;

// Operations

const sessionReset = () => {
  return actions.sessionReset();
};

const sessionSignin = (username, password) => (dispatch) => {
  dispatch(actions.sessionSigninPending(username));

  // Triggers async actions
  api.signin(username, password)
    .then(res => {
      dispatch(actions.sessionSigninSuccess(res.data.auth, res.data.user));
      dispatch(actionsUsers.usersAddSuccess(res.data.user.projectid, res.data.user));
      dispatch(sessionRefreshAuto(true));

      applyUserPref(res.data.user);
    })
    .catch(error => {
      dispatch(actions.sessionSigninFailure(getErrorMessage(error)));
    });
};

const sessionSignout = () => (dispatch, getState) => {
  dispatch(sessionRefreshAuto(false));
  dispatch(actions.sessionSignoutPending());

  const apiToken = sessionSelectors.getToken(getState());

  // Triggers async actions
  api.signout(apiToken)
    .then(res => {
      dispatch(actions.sessionSignoutSuccess(res.data.auth));
      dispatch(actionsUsers.usersReset());
      // TODO after logout: reset all state. or entire redux via reducer, see https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store
      // TODO after logout: cancel all API requests, see https://github.com/axios/axios#cancellation
    })
    .catch(error => {
      dispatch(actions.sessionSignoutFailure(getErrorMessage(error)));
    });
};

const sessionRefresh = () => (dispatch, getState) => {
  dispatch(actions.sessionRefreshPending());

  const apiToken = sessionSelectors.getToken(getState());

  // Triggers async actions
  api.refresh(apiToken)
    .then(res => {
      dispatch(actions.sessionRefreshSuccess(res.data.auth, res.data.user));

      // Refreshing the session also applies the latest user preferences:
      applyUserPref(res.data.user);
      // A user editing itself will automatically trigger a session refresh for this reason. (see users/operations.js)
    })
    .catch(error => {
      dispatch(actions.sessionRefreshFailure(getErrorMessage(error)));
    });
};

const sessionRefreshAuto = (enabled) => (dispatch, getState) => {
  clearTimeout(_autorefreshtimeout);
  if (enabled) {
    const tokenclaims = sessionSelectors.getTokenClaims(getState());
    const exp = tokenclaims ? tokenclaims.exp : 0;

    const expireDate = new Date(1000 * exp);
    const currentDate = new Date();                  // If server or user have a weird time, this wont work.
    const msRemain = expireDate - currentDate;

    const msRenew = msRemain * (
      AUTOREFRESH.FACTOR_MINWAIT +
      // Spread requests to avoid "synchronizing hammer effect" on slow server by users:
      ((AUTOREFRESH.FACTOR_MAXWAIT - AUTOREFRESH.FACTOR_MINWAIT) * Math.random())
    );

    //console.log('Refreshing in ', msRenew/1000, ' seconds.');

    if (msRenew > 0) {
      _autorefreshtimeout = setTimeout(
        () => {
          dispatch(sessionRefresh());
          dispatch(sessionRefreshAuto(true));
        },
        msRenew
      );
    } else {
      console.warn('Session expired, refresh to late');
    }
  }
};

const sessionCurrentProjectid = projectid => {
  return actions.sessionCurrentProjectid(projectid);
};

const sessionPasswordForgot = (username) => (dispatch, getState) => {
  dispatch(actions.sessionPasswordForgotPending());

  // Triggers async actions
  api.passwordforgot(username)
    .then(res => {
      dispatch(actions.sessionPasswordForgotSuccess());
    })
    .catch(error => {
      dispatch(actions.sessionPasswordForgotFailure(getErrorMessage(error)));
    });
};

const sessionPasswordReset = (resettoken, newpassword) => (dispatch, getState) => {
  dispatch(actions.sessionPasswordResetPending());

  //console.log('TODO sessionPasswordReset', resettoken, newpassword);

  // Triggers async actions
  api.passwordreset(resettoken, newpassword)
    .then(res => {
      dispatch(actions.sessionPasswordResetSuccess());

      // todo signin?
      //dispatch(actions.sessionSigninSuccess(res.data.auth, res.data.user));  but also do what tis operation does??
    })
    .catch(error => {
      dispatch(actions.sessionPasswordResetFailure(getErrorMessage(error)));
    });
};

export default {
  sessionReset,
  sessionSignin,
  sessionSignout,
  sessionRefresh,
  //sessionRefreshAuto,
  sessionCurrentProjectid,
  sessionPasswordForgot,
  sessionPasswordReset,
};
