import { connect } from "react-redux";

import { sessionSelectors } from "state/ducks/session";
import AuthWrap from "../components/auth/AuthWrap";

const mapStateToProps = state => {
  return {
    authenticated: sessionSelectors.getAuthenticated(state),
  }
};

const mapDispatchToProps = {};

const ConnectedProjectsWrap = connect(mapStateToProps, mapDispatchToProps)(AuthWrap);

export default ConnectedProjectsWrap;
