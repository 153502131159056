import axiosWithoutInterceptors from "axios";
import { axios, urls } from "logic/apiutils";
import { version } from 'logic/appmeta';

const signin = (username, password) => {
  const config = {
    method: 'post',
    url   : urls.login,
    data  : {
      format: 0,
      login : {
        username,
        password,
        version,
      },
    },
  };

  // Not using axiosIntercepted here because the 401 response triggers a new 401 response: a loop.
  return axiosWithoutInterceptors(config);
};

const signout = (apiToken) => {
  const config = {
    method : 'delete',
    url    : urls.login,
    headers: {
      Authorization: 'Bearer ' + apiToken,
    },
    data   : undefined,
  };

  // Not using axiosIntercepted here because the 401 response triggers a new 401 response: a loop.
  return axiosWithoutInterceptors(config);
};

const refresh = (apiToken) => {
  const config = {
    method : 'put',
    url    : urls.login,
    headers: {
      Authorization: 'Bearer ' + apiToken,
    },
    data   : {
      format: 0,
      login : {
        version,
      },
    },
  };

  return axios(config);
};

const passwordforgot = (username) => {
  const config = {
    method: 'post',
    url   : urls.password,
    data  : {
      format: 0,
      password : {
        username : username,
      },
    },
  };

  return axios(config);
}

const passwordreset = (resettoken, password) => {
  const config = {
    method: 'put',
    url   : urls.password,
    data  : {
      format: 0,
      password : {
        token   : resettoken,
        password: password,
      },
    },
  };

  return axios(config);
}

export default {
  signin,
  signout,
  refresh,
  passwordforgot,
  passwordreset,
};
