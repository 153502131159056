// @formatter:off
const SESSION_RESET           = "SESSION_RESET";
const SESSION_SIGNIN_PENDING  = "SESSION_SIGNIN_PENDING";
const SESSION_SIGNIN_SUCCESS  = "SESSION_SIGNIN_SUCCESS";
const SESSION_SIGNIN_FAILURE  = "SESSION_SIGNIN_FAILURE";
const SESSION_SIGNOUT_PENDING = "SESSION_SIGNOUT_PENDING";
const SESSION_SIGNOUT_SUCCESS = "SESSION_SIGNOUT_SUCCESS";
const SESSION_SIGNOUT_FAILURE = "SESSION_SIGNOUT_FAILURE";
const SESSION_REFRESH_PENDING = "SESSION_REFRESH_PENDING";
const SESSION_REFRESH_SUCCESS = "SESSION_REFRESH_SUCCESS";
const SESSION_REFRESH_FAILURE = "SESSION_REFRESH_FAILURE";
const SESSION_CURRENT         = "SESSION_CURRENT";
const SESSION_PASSWORDFORGOT_PENDING = "SESSION_PASSWORDFORGOT_PENDING";
const SESSION_PASSWORDFORGOT_SUCCESS = "SESSION_PASSWORDFORGOT_SUCCESS";
const SESSION_PASSWORDFORGOT_FAILURE = "SESSION_PASSWORDFORGOT_FAILURE";
const SESSION_PASSWORDRESET_PENDING = "SESSION_PASSWORDRESET_PENDING";
const SESSION_PASSWORDRESET_SUCCESS = "SESSION_PASSWORDRESET_SUCCESS";
const SESSION_PASSWORDRESET_FAILURE = "SESSION_PASSWORDRESET_FAILURE";

export default {
  SESSION_RESET,
  SESSION_SIGNIN_PENDING,
  SESSION_SIGNIN_SUCCESS,
  SESSION_SIGNIN_FAILURE,
  SESSION_SIGNOUT_PENDING,
  SESSION_SIGNOUT_SUCCESS,
  SESSION_SIGNOUT_FAILURE,
  SESSION_REFRESH_PENDING,
  SESSION_REFRESH_SUCCESS,
  SESSION_REFRESH_FAILURE,
  SESSION_CURRENT,
  SESSION_PASSWORDFORGOT_PENDING,
  SESSION_PASSWORDFORGOT_SUCCESS,
  SESSION_PASSWORDFORGOT_FAILURE,
  SESSION_PASSWORDRESET_PENDING,
  SESSION_PASSWORDRESET_SUCCESS,
  SESSION_PASSWORDRESET_FAILURE,
};
