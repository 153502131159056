import { createSelector } from "reselect";

const getUsers = (state, projectid) => {
  if (!state.usersState.list.idsByProjectId.hasOwnProperty(projectid)) return [];

  return (
    state.usersState.list.idsByProjectId[projectid]
      .map(id => state.usersState.list.byId[id])
      .sort((a, b) => (a.id - b.id))
  );
};

const getUsersMemoized = createSelector(
  (state) => state.usersState.list.idsByProjectId,
  (state) => state.usersState.list.byId,
  (state, projectid) => projectid,
  (idsByProjectId, byId, projectid) => getUsers({usersState:{ list:{ idsByProjectId, byId } }}, projectid)
);

const getUser = (state, projectid, userid) => {
  //if (!state.playersState.list.idsByProjectId.hasOwnProperty(projectid)) return null;
  //if (!state.playersState.list.idsByProjectId[projectid].includes(userid)) return null;
  if (!state.usersState.list.byId.hasOwnProperty(userid)) return null;

  return state.usersState.list.byId[userid];
}

const getUsersAll = (state) => {
  // Get all users this user has access to, not just those within a project.
  // There is no list of all ids ..so lets just get all ever loaded for now
  return (
    state.usersState.list.ids
      .map(id => state.usersState.list.byId[id])
      .filter(u => !u.__deleted)
      .sort((a, b) => (a.id - b.id))
  );
};

const getApicomstatus = (state) => {
  return state.usersState.apicomstatus;
};

export default {
  getUsers: getUsersMemoized,
  getUser,
  getUsersAll,
  getApicomstatus,
};
