import { connect } from "react-redux";
import { sessionOperations, sessionSelectors } from "state/ducks/session";
import MainMenu from "../components/MainMenu";

const mapStateToProps = (state, ownProps) => {
  const currentprojectid = sessionSelectors.getCurrentProjectid(state);

  return {
    authenticated : sessionSelectors.getAuthenticated(state),
    user          : sessionSelectors.getUser(state),
    projectid     : currentprojectid,
    projects      : sessionSelectors.getProjectsflat(state),
    projectclaims : sessionSelectors.getProjectClaims(state, currentprojectid),  // = { scopes, features, projectid, player_restriction, }
  }
};

const mapDispatchToProps = {
  onClickProject: sessionOperations.sessionCurrentProjectid,
  onSignout     : sessionOperations.sessionSignout,
  onRefresh     : sessionOperations.sessionRefresh,
};

const ConnectedHeader = connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  {
    // Fix react-router-bootstrap active page indication:
    pure: false,      // allow update when parent gets re-rendered
    // See documentation example at https://github.com/reduxjs/react-redux/blob/0bf4e1fc9644babea0f6ef582b5eb0dd9bd873f9/docs/troubleshooting.md#my-views-arent-updating-when-something-changes-outside-of-redux
  }
)(MainMenu);

export default ConnectedHeader;
