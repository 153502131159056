import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";

import AuthWrap from "../../containers/AuthWrap";


const NotFound = ({ location }) => {
  const { t } = useTranslation();

  return (
    <AuthWrap location={location}>
      <h3>
        {t(
          'common:notfound.not-found-pathname',
          {
            defaultValue : 'Not found: {{pathname}}',  // TODO i18n with  'Not found: <code>{{pathname}}</code>',
            pathname     : location.pathname,
          }
        )}
      </h3>
      {/* todo make this useful to users. And design like ErrorBoundary */}
    </AuthWrap>
  )
}

NotFound.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export default NotFound