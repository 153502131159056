export const VALID = {
  PLAYERNAME_PREGMATCH       : /^[a-z\d_\-\s]{2,250}$/i,  // copied from API
  PLAYERDESCRIPTION_MAXLENGTH: 2000,  // copied from API
  ENCRYPTIONKEY_PREGMATCH    : /^[a-f\d]{0,16}$/i,  // copied from API
  OPERATINGTIME_PREGMATCH    : /^(0?[0-9]|1[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/i,
  QLITESERIAL_PREGMATCH      : /^\d{2}-\d{5}-\d{2}.*$/i,
  PORT_MIN                   : 1,
  PORT_MAX                   : 65535,
  DISPLAYID_MIN              : 0,
  DISPLAYID_MAX              : 65535,
  WIDTH_MIN                  : 1,
  WIDTH_MAX                  : 65535,
  HEIGHT_MIN                 : 1,
  HEIGHT_MAX                 : 65535,
  DEFAULT_PLAYLIST           : { id : "string"}
};

export const VALID_DIMMERCONFIG = {
  SLOPE_MIN           : 0,
  SLOPE_MAX           : 255,
  BRIGHTNESSLOW_MIN   : 0,
  BRIGHTNESSLOW_MAX   : 255,
  BRIGHTNESSHIGH_MIN  : 0,
  BRIGHTNESSHIGH_MAX  : 255,
  AMBIENTLIGHTLOW_MIN : 0,
  AMBIENTLIGHTLOW_MAX : 255,
  AMBIENTLIGHTHIGH_MIN: 0,
  AMBIENTLIGHTHIGH_MAX: 255,
}

export const PLAYERTYPE = {
  NONE      : null,  // used in project playerrestrictions
  NOVA      : '0', // used in Boels-Cloud environment
  QMLN      : '1',
  QLDVI     : '2',
  QMLNSAFETY: '3', // QMLN variant
  QLK       : '4', // Kruis
  ADDCO     : '5', // used in Boels-Cloud environment
}

export const PLAYERTYPES = [
  PLAYERTYPE.NOVA,
  PLAYERTYPE.QMLN,
  PLAYERTYPE.QLDVI,
  PLAYERTYPE.QMLNSAFETY,
  PLAYERTYPE.QLK,
  PLAYERTYPE.ADDCO,
]

export const WARNINGTYPE = {
  UNKNOWN   : 'unknown',
  PING      : 'ping',
  CONTENT   : 'content',
  BRIGHTNESS: 'brightness',
  WORKTIME  : 'worktime',
  LIBRARY   : 'library',
}

export const PROGRESSTYPE = {
  WARN: 'warn',
  INFO: 'info',
}

export const PING = {
  NORMAL: 'normal',
  //TIMEOUT : 'timeout',
  //'error_no_connect',
}

export const OPERATINGVALUE = {
  ALWAYS: {
    ON : '00:00:00',
    OFF: '23:59:59',
  },
  NEVER : {
    ON : '23:59:59',
    OFF: '23:59:59',
  },
};

export const GPSAVAILABLE = {
  NONE         : 'GPS_NONE',
  TYPE1_ROUTER : 'GPS_TYPE1',  // used in Boels-Cloud env with NOVA players.
  TYPE2_QMLN   : 'GPS_TYPE2',  // used in Boels-Cloud env with NOVA/ADDCO players and Q-Cloud env for QMLN players.
}
export const GPSAVAILABLES = [
  GPSAVAILABLE.NONE,
  GPSAVAILABLE.TYPE1_ROUTER,
  GPSAVAILABLE.TYPE2_QMLN,
]

export const DEFAULT_DIMTABLECONFIG = {
  // Format = the dimtable.config property of player as defined by API JSON.
  // default values taken from QLedText
  setsdimdata     : true, // not used now, here to make it future-proof and allow manual dimdata
  slope           : 15,  // 0 = straight line, 15 = mild S-curve, 255 = steep curve.
  brightnesslow   : 13,  // = 5%
  brightnesshigh  : 255, // = 100%
  ambientlightlow : 0,   // = 0%
  ambientlighthigh: 128, // = 50%
}

export const DEFAULT_PLAYLIST = {
  id          : '',
  _projectid  : '',
  _name       : '',
}

export const DEFAULTPLAYER = {
  active       : false,
  autopublish  : false,
  name         : '',               // Invalid default.
  description  : '',
  defaultplaylist : DEFAULT_PLAYLIST,
  operatingon  : OPERATINGVALUE.ALWAYS.ON,
  operatingoff : OPERATINGVALUE.ALWAYS.OFF,
  // SysOps extras:
  qliteserial  : '',
  ip           : '',
  port         : 0,                // Invalid default.
  encryptionkey: '',
  displayid    : 0,
  playertypeid : PLAYERTYPE.NONE,  // Invalid default. For new player use player_restriction.playertypeid if not null
  width        : 0,                // Invalid default. For new player use player_restriction.width if not null
  height       : 0,                // Invalid default. For new player use player_restriction.height if not null
  gpsavailable : GPSAVAILABLE.NONE,
  hasdviboard  : false,
  battavailable: false,
  /* Generated after Add modal, before API POST:
  dimtable     : {
    config : DEFAULT_DIMTABLECONFIG,
    dimdata: null,                 // Invalid default. For new player use makeDimdata(DEFAULT_DIMTABLECONFIG, player_restriction.playertypeid)
  },
  */
}
