import React from "react";
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";

import { urls, injectUrlValues } from "logic/apiutils";
import logo from "views/resources/logo.svg";


const ThemedLogo = ({ theme }) => {
  const { t } = useTranslation();

  // Default logo
  if (!theme || !theme.logo) return (
    <img
      src={logo}
      alt={t('common:mainmenu.logo-qcloud',{defaultValue:'Q-Cloud logo'})}
    />
  );

  // Theme logo
  return (
    <img
      src={injectUrlValues(urls.projectthemefile, {
        projectthemefile: theme.logo,
      })}
      alt={t('common:mainmenu.logo-project',{defaultValue:'Q-Cloud project logo'})}
    />
  )
}

ThemedLogo.propTypes = {
  theme : PropTypes.shape({
    logo : PropTypes.string,
  }),
};

export default ThemedLogo;