import React from 'react';
import { useTranslation } from "react-i18next";
import { Button, ButtonGroup } from "react-bootstrap";

import CONFIG from 'config/config';
import { LANG } from 'logic/user/constants';


const ChangeLanguageButton = () =>  {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <span>{t('common:change-language',{defaultValue:'Change language'})}{' '}</span>
      <br />
      <ButtonGroup
        bsSize="xsmall"
      >
        <Button
          onClick={() => i18n.changeLanguage(LANG.EN)}
          active={i18n.language===LANG.EN}
        >EN</Button>
        <Button
          onClick={() => i18n.changeLanguage(LANG.FR)}
          active={i18n.language===LANG.FR}
        >FR</Button>
        <Button
          onClick={() => i18n.changeLanguage(LANG.NL)}
          active={i18n.language===LANG.NL}
        >NL</Button>
        {CONFIG.debug ? (
          <Button
            onClick={() => i18n.changeLanguage('cimode')}
            active={i18n.language==='cimode'}
          >key</Button>
        ) : null}
      </ButtonGroup>
    </div>
  );
}

export default ChangeLanguageButton;
