const isProduction = process.env.NODE_ENV === "production";

const ROOT = isProduction
  ? ''                                  // Production
  : 'https://dev2-cloud.q-lite.com';  // Development

const CONFIG = {
  apiroot : `${ROOT}/api/`,
  dataroot: `${ROOT}/data/`,
  debug   : !isProduction,
  googlemapsapikey : isProduction
    ? 'AIzaSyCLqyP_pAHJt0NVWagjxeMjN2ogyCJ6yT8'  // voor PRODUCTION en beperkt tot Maps API en domein *.q-lite.com/*
    : 'AIzaSyDoTYrBjQn5eWlNcdRyIKDpipoQCChukyo', // voor DEVELOPMENT en werkt ook op localhost
};

export default CONFIG;
