import React, { useState } from "react";
import PropTypes from 'prop-types';
import { Form, FormGroup, FormControl, Button } from 'react-bootstrap';
import FontAwesome from 'react-fontawesome';
import { useTranslation, } from "react-i18next";

import Oopsy from "../../common/Oopsy";
import { TABS } from "./index";


const ForgotForm = ({apicomstatus, onTab, onPasswordForgot, }) => {
  const { t } = useTranslation();
  const [ sent, setSent ] = useState(false);
  const [ username, setUsername ] = useState('');

  const pending = apicomstatus && apicomstatus.pending;

  return (
    <div>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          //onBlabla();
        }}
      >
        <FormGroup>
          <p>
            {t('auth:passwordforgot.please-enter-username',{defaultValue:
                'Please enter your username so we can send an email that allows you to reset the password.'
            })}
          </p>
          <FormControl
            disabled={pending || sent}
            name="username"
            onChange={(e) => { setUsername(e.target.value); }}
            placeholder={t('auth:username-placeholder',{defaultValue:'Username'})}
            required
            type="text"
            value={username}
          />
          <Button
            bsSize="large"
            bsStyle="primary"
            className="btn-block"
            disabled={pending || sent}
            onClick={(e) => {
              e.preventDefault();
              setSent(true);
              onPasswordForgot(username.trim());
            }}
          >
            <FontAwesome name={pending ? 'refresh' : 'sign-in'} spin={pending} />
            {' '}
            {t('auth:passwordforgot.request-new-password',{defaultValue:'Request new password'})}
          </Button>
        </FormGroup>

        <Oopsy apicomstatus={apicomstatus} />

        {sent ? (
          <span>
            <h2>{t('auth:passwordforgot.sent1-email-sent',{defaultValue:'Email sent!'})}</h2>
            <p>{t('auth:passwordforgot.sent2-please-open-link',{defaultValue:'Please open the link it contains to reset your account password.'})}</p>
            <p>{t('auth:passwordforgot.sent3-in-case-not-received',{defaultValue:'In case you did not receive it within minutes its good to doublecheck the username entered, and that a valid email was configured with your account.'})}</p>
          </span>
        ): null }

        <p className="help-block">
          <Button bsStyle="link" disabled={pending} onClick={(e)=>{onTab(TABS.SIGNIN)}}>
            {t('auth:passwordreset.back-to-login-page',{defaultValue:'Back to login page'})}
          </Button>
        </p>
      </Form>
    </div>
  );
}

ForgotForm.propTypes = {
  apicomstatus : PropTypes.object.isRequired,
  onTab        : PropTypes.func.isRequired,
  onPasswordForgot        : PropTypes.func.isRequired,
};

export default ForgotForm;
