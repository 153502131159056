import types from "./types";

export const sessionReset = () => ({
  type   : types.SESSION_RESET,
  payload: {},
});

export const sessionSigninPending = (username) => ({
  type   : types.SESSION_SIGNIN_PENDING,
  payload: {
    username,
  }
});

export const sessionSigninSuccess = (auth, user) => ({
  type   : types.SESSION_SIGNIN_SUCCESS,
  payload: {
    auth,
    user,
  }
});

export const sessionSigninFailure = error => ({
  type   : types.SESSION_SIGNIN_FAILURE,
  payload: {
    error,
  }
});

export const sessionSignoutPending = () => ({
  type   : types.SESSION_SIGNOUT_PENDING,
  payload: {}
});

export const sessionSignoutSuccess = (auth) => ({
  type   : types.SESSION_SIGNOUT_SUCCESS,
  payload: {
    auth,
  }
});

export const sessionSignoutFailure = error => ({
  type   : types.SESSION_SIGNOUT_FAILURE,
  payload: {
    error,
  }
});

export const sessionRefreshPending = () => ({
  type   : types.SESSION_REFRESH_PENDING,
  payload: {}
});

export const sessionRefreshSuccess = (auth, user) => ({
  type   : types.SESSION_REFRESH_SUCCESS,
  payload: {
    auth,
    user,
  }
});

export const sessionRefreshFailure = error => ({
  type   : types.SESSION_REFRESH_FAILURE,
  payload: {
    error,
  }
});

export const sessionCurrentProjectid = (projectid) => ({
  type   : types.SESSION_CURRENT,
  payload: {
    projectid,
  }
})

export const sessionPasswordForgotPending = () => ({
  type   : types.SESSION_PASSWORDFORGOT_PENDING,
  payload: {}
});

export const sessionPasswordForgotSuccess = (username) => ({
  type   : types.SESSION_PASSWORDFORGOT_SUCCESS,
  payload: {
    username,
  }
});

export const sessionPasswordForgotFailure = error => ({
  type   : types.SESSION_PASSWORDFORGOT_FAILURE,
  payload: {
    error,
  }
});

export const sessionPasswordResetPending = () => ({
  type   : types.SESSION_PASSWORDRESET_PENDING,
  payload: {}
});

export const sessionPasswordResetSuccess = (auth, user) => ({
  type   : types.SESSION_PASSWORDRESET_SUCCESS,
  payload: {
    auth,
    user,
  }
});

export const sessionPasswordResetFailure = error => ({
  type   : types.SESSION_PASSWORDRESET_FAILURE,
  payload: {
    error,
  }
});

export default {
  sessionReset,
  sessionSigninPending,
  sessionSigninSuccess,
  sessionSigninFailure,
  sessionSignoutPending,
  sessionSignoutSuccess,
  sessionSignoutFailure,
  sessionRefreshPending,
  sessionRefreshSuccess,
  sessionRefreshFailure,
  sessionCurrentProjectid,
  sessionPasswordForgotPending,
  sessionPasswordForgotSuccess,
  sessionPasswordForgotFailure,
  sessionPasswordResetPending,
  sessionPasswordResetSuccess,
  sessionPasswordResetFailure,
};
