import axios from "axios";
import axiosCancel from 'axios-cancel';

import { reduxStore } from "index.js";
import sessionOperations from 'state/ducks/session/operations';


// Redirect user to login page when API responds with 401 Unauthorized.
// This happens when browser tries to refresh session token, using a token thats no longer valid.
// Based on https://stackoverflow.com/questions/48743474/how-to-detect-a-401-with-axios-and-stop-the-console-error
// Issue #437


// Exceptional HTTP API errors that kick the webapp in another gear.
const UNAUTHORIZED = 401;
const FORBIDDEN = 403;
const NOTFOUND = 404;
//const TOMANYREQUESTSERROR = 429;  // inform user?
//const INTERNALSERVERERROR = 500;  // inform user?


// Extend axios to intercept some errors, so the app can respond to recover from them.
// Currently, requests are not queued or retried. There might be room for improvement here.
// The login page wil redirect back to where the problem occured.
const axiosIntercepted = axios.create();
axiosIntercepted.interceptors.response.use(
  response => response,
  error => {
    // Fail fast on invalid error:
    if (!error || !error.response || !error.response.status) return Promise.reject(error);
    // This might happen:
    //  - when we cancel a request,
    //  - when browser is offline,
    //  - when browser blocks request, (including due to CORS)
    //  - when an exception is unhandled, (including bug in error handling)
    //  - and possibly more.
    // Of no concern here because we cant help/fix it here and its detectable outside of this interceptor.
    // There is not even a sensible console error we can output here that actually helps.

    const { status } = error.response;

    if (status === UNAUTHORIZED) {
      // This can happen after a failed session refresh, for example session got expired while browser/device was suspended.
      console.debug('API says 401 Unauthorized, so redirecting to login page..')
      reduxStore.dispatch(sessionOperations.sessionSignout());
    }

    if (status === FORBIDDEN) {
      // This can happen when permissions just changed, on any request.
      // If not: check for BUGS! For example the WebApp is not using the same scopes as the API.
      console.debug('API says 403 Forbidden, so refreshing session.. (expecting changed permissions)')
      reduxStore.dispatch(sessionOperations.sessionRefresh());
    }

    if (status === NOTFOUND) {
      // This can happen after any request, with bad/expired session. For example when suspended browser/device wakes up.
      console.debug('API says 404 Not Found, so refreshing session.. (expecting error 401 Unauthorized)')
      reduxStore.dispatch(sessionOperations.sessionRefresh());
    }

    // If the browser says "Check your internet connection." this is no response error that this axios interceptor can see.
    // This can happen when internet connection is lost, or security prohibits it: for example API response 401 for CORS OPTIONS request.
    // It might be detectable by `!error.response.status` outside of this interceptor.. like `getErrorMessage()` in `apiutils.js` perhaps.

    return Promise.reject(error);
  }
)

// We use `axios-cancel` to cancel uploads.
axiosCancel(axiosIntercepted, {
  debug: false // default
});

export default axiosIntercepted;