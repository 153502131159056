const defaultScope = {
  read  : false,
  edit  : false,
  manage: false,
  sysops: false
};

const defaultScopes = {
  players  : defaultScope,
  playlists: defaultScope,
  libraries: defaultScope,
  projects : defaultScope,
  users    : defaultScope,
  scenarios: defaultScope ,
  schedules: defaultScope,
  templates: defaultScope,
};

const defaultFeatures = {
  /* This object is empty on purpose for obscurity. Absense of property equals false.
     Just imagine properties like:
  bitmaps: false,
  videos: false,
  flash: false,
  */
};

const defaultPlayerRestriction = {
  playertypeid: null,
  width       : null,
  height      : null,
}

const defaultParameters = null;
/* This is usually an object, stored as unprocessed JSON. Example for QLK projects:
  {"parameters":{"default_playlistid":"122","day_playlistid":"123","night_playlistid":"124"}}
*/

export const AUTOREFRESH = {
  // autorefresh occurs between 60..90%
  FACTOR_MINWAIT: 0.6,
  FACTOR_MAXWAIT: 0.9,
};

export default {
  defaultScope,
  defaultScopes,
  defaultFeatures,
  defaultPlayerRestriction,
  defaultParameters,
  AUTOREFRESH,
};
