import { connect } from "react-redux";
import { sessionOperations, sessionSelectors } from "state/ducks/session";
import ResetForm from "../components/auth/ResetPage/ResetForm";

const mapStateToProps = state => ({
  apicomstatus : sessionSelectors.getApicomstatus(state),
});

const mapDispatchToProps = {
  onPasswordReset : sessionOperations.sessionPasswordReset,
};

const ConnectedResetForm = connect(mapStateToProps, mapDispatchToProps)(ResetForm);

export default ConnectedResetForm;
