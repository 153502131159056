import React from "react";
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom'
import { Navbar, Nav, NavItem, } from 'react-bootstrap';
import { LinkContainer, } from 'react-router-bootstrap';
import { useTranslation } from "react-i18next";

import ThemedLogo from "./ThemedLogo";
import './index.css';
import NavbarMenu from "./NavbarMenu";


// Opgesplitst in sub-componenten met React.Fragment (!) omdat de Navbar's niet goed functioneren zonder Navbar als directe parent
const Index = (props) => {
  const {
          authenticated, projectid, projects,
        } = props;

  const { t } = useTranslation();

  const project = projects.find(p => p.id === projectid);
  const theme = project && project.theme && project.theme.json ? project.theme.json : null;

  return (
    <Navbar
      fluid
      inverse
      fixedTop
      collapseOnSelect
      style={theme && theme.color
        ? {borderColor: theme.color}
        : null
      }
    >
      <Navbar.Header>
        {authenticated ? (
          <Link to={authenticated ? ("/projects/" + projectid) : "/signin"}>
            <ThemedLogo theme={theme} />
          </Link>
        ) : null}
        {authenticated ? <Navbar.Toggle/> : null}
      </Navbar.Header>
      {authenticated ? (
        <NavbarMenu
          {...props}
          projectname={project && project.name ? project.name : null}
        />
      ) : (
        <Nav pullRight>
          <LinkContainer to="/signin">
            <NavItem>
              {t('common:mainmenu.sign-in',{defaultValue:'Sign in'})}
            </NavItem>
          </LinkContainer>
        </Nav>
      )}
    </Navbar>
  )
};

Index.propTypes = {
  authenticated : PropTypes.bool,
  projectid     : PropTypes.string,
  projects      : PropTypes.arrayOf(
    PropTypes.shape({
      id  : PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
};

export default withRouter(Index);
