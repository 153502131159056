import React from "react";
import PropTypes from 'prop-types';
import { Alert, Button, } from 'react-bootstrap';
import FontAwesome from 'react-fontawesome';
import { withTranslation } from "react-i18next";

import ErrorBoundary from '../../common/ErrorBoundary';
import { getResetPasswordValid } from "logic/user/sanitisers";
import Oopsy from "../../common/Oopsy";


class ResetForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      password1 : '',
      password2 : '',
    }
  }

  render() {
    const { match, apicomstatus, t, onPasswordReset, } = this.props;
    const { password1, password2, } = this.state;

    const { resettoken } = match.params;

    const valid = getResetPasswordValid({resettoken, password1, password2});

    const paswEmpty = !password1 &&  !password2;
    const paswEqual =  password1 ===  password2;
    const paswValid = false; //isValidPassword(password1);

    return (
      <ErrorBoundary>
        <Oopsy apicomstatus={apicomstatus} />

        <form>
          {resettoken ? (
            <input type="hidden" ref="resettoken" value={resettoken} required />
          ) : (
            <span>
              <p>{t('auth:passwordreset.password-reset-token',{defaultValue:'Password reset token:'})}</p>
              <input
                type="text"
                placeholder={t('auth:passwordreset.reset-token',{defaultValue:'Reset token'})}
                value={resettoken}
                onChange={(e)=>{this.setState({resettoken: e.target.value})}}
                required
              />
            </span>
          )}

          <p>{t('auth:passwordreset.enter-a-new-password-twice',{defaultValue:'Enter a new password twice:'})}</p>
          <input
            type="password"
            //placeholder="Password"
            value={password1}
            onChange={(e)=>{this.setState({password1 :e.target.value})}}
            required
          />
          <input
            type="password"
            //placeholder="Password again"
            alue={password2}
            onChange={(e)=>{this.setState({password2 :e.target.value})}}
            required
          />
          {paswEmpty || !paswValid
            ? <Alert bsStyle="warning">
                {t('auth:passwordreset.password-must-contain1',{defaultValue:'The new password should be at least 8 characters long and contain:'})}
                <ul>
                  <li>{t('auth:passwordreset.password-must-contain2',{defaultValue:'at least one uppercase A-Z'})}</li>
                  <li>{t('auth:passwordreset.password-must-contain3',{defaultValue:'at least one lowercase a-z'})}</li>
                  <li>{t('auth:passwordreset.password-must-contain4',{defaultValue:'at least one number 0-9'})}</li>
                  <li>{t('auth:passwordreset.password-must-contain5',{defaultValue:'at least one special character like'})}{' '}<code>$@$!%*?&</code></li>
                </ul>
              </Alert>
            : (!paswEqual
                ? <Alert bsStyle="warning">{t('auth:passwordreset.oops-you-must-enter-same-password-twice',{defaultValue:'You must enter the same password twice.'})}</Alert>
                : <Alert bsStyle="success">{t('auth:passwordreset.ok-password-meets-all-criteria',{defaultValue:'The new password meets all criteria.'})}</Alert>
          )}
          <p>Your previous password wil no longer work.</p>
          <Button
            bsSize="large"
            bsStyle="primary"
            className="btn-block"
            disabled={!valid.everything}
            onClick={() => {
              onPasswordReset(resettoken, password1)
            }}
          >
            <FontAwesome name={apicomstatus.pending ? 'refresh' : 'sign-in'} spin={apicomstatus.pending} />
            {' '}
            {t('auth:passwordreset.continue-to-login',{defaultValue:'Continue to login'})}
          </Button>
        </form>
        {/*
        <pre>match={JSON.stringify(match, null, 2)}</pre>
        <pre>valid={JSON.stringify(valid, null, 2)}</pre>
        */}
      </ErrorBoundary>
    );
  }
}

ResetForm.propTypes = {
  match  : PropTypes.shape({
    params : PropTypes.shape({
      resettoken: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  t : PropTypes.func.isRequired,
  apicomstatus : PropTypes.shape({
    pending : PropTypes.bool.isRequired,
  }).isRequired,
};


export default withTranslation()(ResetForm);