import React, { useState } from "react";
import { Grid, PageHeader } from 'react-bootstrap';
import { useTranslation } from "react-i18next";

import AppVersion from "./AppVersion";
import SigninForm from "./SigninForm";
import ForgotForm from "./ForgotForm";

import ErrorBoundary from '../../common/ErrorBoundary';



export const TABS = {
  SIGNIN : 'signin',
  FORGOT : 'forgot',
}

const SigninPage = (props) => {
  const { t } = useTranslation();
  const [ tab, setTab ] = useState(TABS.SIGNIN);

  switch (tab) {
    case TABS.FORGOT:
      return (
        <ErrorBoundary>
          <div className="splash">
            <Grid className="vertical-center col-xs-8 col-sm-6 col-md-4 col-xs-offset-2 col-sm-offset-3 col-md-offset-4">
              <div>
                <PageHeader>{t('auth:reset-password', { defaultValue: 'Reset password' })}</PageHeader>
                <ForgotForm {...props} onTab={setTab} />
              </div>
            </Grid>
          </div>
        </ErrorBoundary>
      );
    case TABS.SIGNIN:
    default:
      return (
        <ErrorBoundary>
          <div className="splash">
            <Grid className="vertical-center col-xs-8 col-sm-6 col-md-4 col-xs-offset-2 col-sm-offset-3 col-md-offset-4">
              <div>
                <PageHeader>{t('auth:sign-in', { defaultValue: 'Sign in' })}</PageHeader>
                <SigninForm {...props} onTab={setTab}/>
              </div>
            </Grid>
          </div>
          <AppVersion />
        </ErrorBoundary>
      );
  }
}

export default SigninPage;