// @formatter:off
import CONFIG from '../config/config';
import axiosIntercepted from "./apiutils-axios";
import { escapeRegExp } from "./js-misc/regex";

const qwolk_apiroot = CONFIG.apiroot;
const qwolk_dataroot = CONFIG.dataroot;


// Change these URLs per cloud version:
export const urls = {
  // QWOLK API v0
  login             : qwolk_apiroot + "v0/login/",
  playlists         : qwolk_apiroot + "v0/projects/:projectid/playlists/",
  playlist          : qwolk_apiroot + "v0/projects/:projectid/playlists/:playlistid/",
  playlistitems     : qwolk_apiroot + "v0/projects/:projectid/playlists/:playlistid/items/",
  playlistitem      : qwolk_apiroot + "v0/projects/:projectid/playlists/:playlistid/items/:playlistitemid/",
  players           : qwolk_apiroot + "v0/projects/:projectid/players/?extra_fields=_status,_firstplaylistitem,_warnings_actual,_pings,_transports&include=:includesubprj",
  player            : qwolk_apiroot + "v0/projects/:projectid/players/:playerid/?extra_fields=_status,_firstplaylistitem,_warnings_actual,_pings,_transports,_dimtable,_traffic,_scenarios,_warnings,_pendingcontent",
  //player_pendingcontent:qwolk_apiroot+"v0/projects/:projectid/players/:playerid/?extra_fields=_pendingcontent",
  player_publish    : qwolk_apiroot + "v0/projects/:projectid/players/:playerid/publish",
  player_poll       : qwolk_apiroot + "v0/projects/:projectid/players/:playerid/poll",
  projects          : qwolk_apiroot + "v0/projects/",
  project           : qwolk_apiroot + "v0/projects/:projectid/",
  libraries         : qwolk_apiroot + "v0/projects/:projectid/libraries/",
  library           : qwolk_apiroot + "v0/projects/:projectid/libraries/:libraryid/",
  libraryitems      : qwolk_apiroot + "v0/projects/:projectid/libraries/:libraryid/items/",
  libraryitem       : qwolk_apiroot + "v0/projects/:projectid/libraries/:libraryid/items/:libraryitemid/",
  libraryfile       : qwolk_dataroot + ":libraryfile",       // always in /data/libraryitems/
  // Added in rev1
  scenarios         : qwolk_apiroot + "v0/projects/:projectid/scenarios/",
  scenario          : qwolk_apiroot + "v0/projects/:projectid/scenarios/:scenarioid/",
  scenario_run      : qwolk_apiroot + "v0/projects/:projectid/scenarios/:scenarioid/run",
  scenario_stop     : qwolk_apiroot + "v0/projects/:projectid/scenarios/:scenarioid/stop",
  scenarioitems     : qwolk_apiroot + "v0/projects/:projectid/scenarios/:scenarioid/items/",
  scenarioitem      : qwolk_apiroot + "v0/projects/:projectid/scenarios/:scenarioid/items/:scenarioitemid/",
  users             : qwolk_apiroot + "v0/users/",
  user              : qwolk_apiroot + "v0/users/:userid/",
  projectusers      : qwolk_apiroot + "v0/projects/:projectid/users/",          // the scopes (permissions) of users on project (+subprojects?)
  projectuser       : qwolk_apiroot + "v0/projects/:projectid/users/:userid/",
  // Added in rev2
  schedules         : qwolk_apiroot + "v0/projects/:projectid/schedules/",
  schedule          : qwolk_apiroot + "v0/projects/:projectid/schedules/:scheduleid/",
  scheduleplayers   : qwolk_apiroot + "v0/projects/:projectid/schedules/:scheduleid/players/",
  scheduleplayer    : qwolk_apiroot + "v0/projects/:projectid/schedules/:scheduleid/players/:scheduleplayerid/",
  scheduleplaylists : qwolk_apiroot + "v0/projects/:projectid/schedules/:scheduleid/playlists/",
  scheduleplaylist  : qwolk_apiroot + "v0/projects/:projectid/schedules/:scheduleid/playlists/:scheduleplaylistid/",
  // Added in cloud3
  projectthemefile  : qwolk_dataroot + "projectthemes/:projectthemefile",  // always in /data/projectthemes/
  // Added in cloud4
  password          : qwolk_apiroot + "v0/password/",
  // Added in cloud5
  templates         : qwolk_apiroot + "v0/projects/:projectid/templates/",
  template          : qwolk_apiroot + "v0/projects/:projectid/templates/:templateid/",
  // Added in cloud7 hotfix2
  contracts         : qwolk_apiroot + "v0/contracts/",
  contract          : qwolk_apiroot + "v0/contracts/:contractid/",
};

/**
 *
* @param url_template For example `v0/projects/:projectid/playlists/`
* @param arr_values For example `{ projectid:"FOO" }`
* @param unsafe Dont escape special chars so you can inject/append a path, like `/data/prj123/lib123/image123.png`.
* @returns {*}
 */
export const injectUrlValues = (url_template, arr_values, unsafe=false) => {
  var newurl = url_template;

  // Replace keys with values
  for (var key in arr_values) if (arr_values.hasOwnProperty(key)) {
    newurl = newurl.replace(
      new RegExp(':' + escapeRegExp(key), 'g'),
      unsafe ? encodeURI(arr_values[key]) : encodeURIComponent(arr_values[key])
    );
    //console.log('injectUrlValues()', url_template, key, arr_values[key]);
  }

  return newurl;
};

// Get error message from Axios .catch() error object
export const getErrorMessage = (error) => {
  if (CONFIG.debug) console.warn("DEBUG OUTPUT - Error using API:", error);

  if (error.response && error.response.status) {
    return (
      // HTTP error
      error.response.status + ': ' + (
        (error.response.data && error.response.data.error && error.response.data.error.message)
          ? error.response.data.error.message  // API error optional details
          : error.response.statusText          // HTTP error default text
      )
    );
  }

  if (error.request) {
    console.warn("Error in request", error.request);
  }

  return ('Check your internet connection.');
};

export const axios = axiosIntercepted;