import React, { Component, Suspense, lazy } from "react";
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import MainMenu from "./containers/MainMenu";
import SigninPage from './containers/SigninPage';
import ResetPage from './components/auth/ResetPage';
import NotFound from "./components/NotFound";
import Loading from "./components/common/Loading";
import "./resources/QcloudApp.css";
import { version } from 'logic/appmeta';
import ErrorBoundary from "./components/common/ErrorBoundary";

// Code-splitting / bundle
const LazyProjectsAuthWrap = lazy(() => import(/* webpackChunkName: "projects-kitchensink" */ "./components/projects"));
const LazyManageMyProjectsAuthWrap = lazy(() => import(/* webpackChunkName: "projects-manage" */ "./components/projects/ManagePage"));
const LazyContractsAuthWrap = lazy(() => import(/* webpackChunkName: "contracts" */ "./components/contracts/indexAuthWrap"));

// DEBUG
/*
const style = {
  debug : {
    backgroundColor:"red",
    color:"yellow",
    textAlign:"center",
    fontFamily:"monospace",
    bottom: "0px",
    position: "fixed",
    width:"100%",
  },
}
*/

console.log(version);


class App extends Component {
  render() {
    return (
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <div className="App">
          <MainMenu/>
          <ErrorBoundary>
            <Suspense fallback={<Loading />}>
              <Switch>
                {/* All routes that can be rendered here: */}
                {/* @formatter:off */}
                <Route path="/"      exact render={()=>(<Redirect to="/signin" />)} />
                <Route path="/login" exact render={()=>(<Redirect to="/signin" />)} />
                <Route path="/signin"                    component={SigninPage} />
                <Route path="/resetpassword/:resettoken" component={ResetPage} />
                <Route path="/resetpassword"             component={ResetPage} />
                <Route path="/projects/:projectid"       component={LazyProjectsAuthWrap} />
                <Route path="/projects"                  component={LazyManageMyProjectsAuthWrap} />
                {/* todo add /users route */}
                <Route path="/contracts"                 component={LazyContractsAuthWrap} />
                <Route                                   component={NotFound} />
                {/* @formatter:on */}
              </Switch>
            </Suspense>
          </ErrorBoundary>

          {/* DEBUG
          <div style={style.debug}>Version: {version}</div>
          */}
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
