import React from 'react';
import CONFIG from "config/config";

import imgError from 'views/resources/images/error.svg';


/*
// Test ErrorBoundary using <ProblemChild/>
function ProblemChild() {
  throw new Error('Error thrown from problem child');
  return <div>Error</div>; // eslint-disable-line
}
*/

// Something UGLY to help debug during development.
// TODO make pretty for release
const styles = {
  box: {
    borderColor: "#337ab7",
    backgroundColor: "#337ab7",
    color: "#ffffff",
    display: "inline-block",
    padding: "20px",
  },
  image: {
    width: "100px",
    height: "100px",
  }
};

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    console.error(
      'ErrorBoundary did a catch ',
      errorInfo?.componentStack?.split('\n')[1]?.trim() || '( in Sector ZZ9 Plural Z Alpha )',
      '\n',
      error,
    );

    // Display fallback UI
    this.setState({ hasError: true });

    // You can also log the error to an error reporting service
    //logErrorToMyService(error, info);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div style={styles.box}>
          {CONFIG.debug
            ? <img
                src={imgError}
                alt=""
                style={styles.image}
              />
            : null
          }
          {/* todo leuk plaatje bijv
            https://hackernoon.com/error-boundaries-in-react-16-32fb8e185a3
          */}
          <h3>Something went wrong</h3>
          <p>Refreshing the page may help, or try again later.</p>
          {CONFIG.debug ? <code>Zie error info in console output.</code> : null}
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary