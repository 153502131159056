import actions from "./actions";
import sessionOperations from "../session/operations";
import sessionSelectors from "../session/selectors";
import api from "./api";
import { getErrorMessage } from "logic/apiutils";



// test
function withoutPassword(user) {
  const { password, password2, ...others} = user;
  return {...others}
}



const usersReset = () => {
  return actions.usersReset();
};

const usersLoad = (projectid) => (dispatch, getState) => {
  dispatch(actions.usersLoadPending(projectid));

  const apiToken = sessionSelectors.getToken(getState());

  // Triggers async actions
  api.load(apiToken, projectid)
    .then(res => {
      dispatch(actions.usersLoadSuccess(projectid, res.data.users));
    })
    .catch(error => {
      dispatch(actions.usersLoadFailure(projectid, getErrorMessage(error)));
    });
};

const usersAdd = (projectid, user) => (dispatch, getState) => {
  dispatch(actions.usersAddPending(projectid, withoutPassword(user)));

  const apiToken = sessionSelectors.getToken(getState());

  // Triggers async actions
  api.add(apiToken, projectid, user)
    .then(res => {
      dispatch(actions.usersAddSuccess(projectid, withoutPassword(res.data.user)));
    })
    .catch(error => {
      dispatch(actions.usersAddFailure(projectid, withoutPassword(user), getErrorMessage(error)));
    });
};

const usersRemove = (projectid, userid) => (dispatch, getState) => {
  dispatch(actions.usersRemovePending(projectid, userid));

  const apiToken = sessionSelectors.getToken(getState());

  // Triggers async actions
  api.remove(apiToken, projectid, userid)
    .then(res => {
      dispatch(actions.usersRemoveSuccess(projectid, userid, res.data.user));
    })
    .catch(error => {
      dispatch(actions.usersRemoveFailure(projectid, userid, getErrorMessage(error)));
    });
};

const usersUpdate = (projectid, userid, user) => (dispatch, getState) => {
  dispatch(actions.usersUpdatePending(projectid, userid, withoutPassword(user)));

  const apiToken = sessionSelectors.getToken(getState());

  // Triggers async actions
  api.update(apiToken, projectid, userid, user)
    .then(res => {
      dispatch(actions.usersUpdateSuccess(projectid, userid, withoutPassword(res.data.user)));

      // Avoid error 401 for next request, if changing current user. Session refresh also reloads some user preferences like language.
      if (userid === sessionSelectors.getUser(getState()).id)
        dispatch(sessionOperations.sessionRefresh());
    })
    .catch(error => {
      dispatch(actions.usersUpdateFailure(projectid, userid, withoutPassword(user), getErrorMessage(error)));
    });
};

const usersGet = (projectid, userid) => (dispatch, getState) => {
  dispatch(actions.usersGetPending(projectid, userid));

  const apiToken = sessionSelectors.getToken(getState());

  // Triggers async actions
  api.get(apiToken, projectid, userid)
    .then(res => {
      dispatch(actions.usersGetSuccess(projectid, userid, res.data.user));
    })
    .catch(error => {
      dispatch(actions.usersGetFailure(projectid, userid, getErrorMessage(error)));
    });
};

export default {
  usersReset,
  usersLoad,
  usersAdd,
  usersRemove,
  usersUpdate,
  usersGet,
};
