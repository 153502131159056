import { PLAYERTYPE, PLAYERTYPES, } from "./player/constants";


// Show in main menu for these playertypes
export const MODULESUPPORT = {
  LIBRARIES : [PLAYERTYPE.NOVA, PLAYERTYPE.QLDVI, PLAYERTYPE.QLK, PLAYERTYPE.ADDCO, PLAYERTYPE.QMLNSAFETY, ],
  PLAYLISTS : PLAYERTYPES, // all
  SCENARIOS : [PLAYERTYPE.NOVA, PLAYERTYPE.ADDCO, ],
  SCHEDULES : [PLAYERTYPE.NOVA, PLAYERTYPE.QMLN, PLAYERTYPE.QLDVI, ],
  TEMPLATES : [PLAYERTYPE.QLDVI, PLAYERTYPE.QMLNSAFETY, ],
}
