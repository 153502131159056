import React from "react";
import ResetForm from "views/containers/ResetForm";
import { Grid, PageHeader } from 'react-bootstrap';
import { useTranslation } from "react-i18next";

import ErrorBoundary from '../../common/ErrorBoundary';


const ResetPage = (props) => {
  const { t } = useTranslation();
  return (
    <ErrorBoundary>
      <div className="splash">
        <Grid className="vertical-center col-xs-8 col-sm-6 col-md-4 col-xs-offset-2 col-sm-offset-3 col-md-offset-4">
          <div>
            <PageHeader>{t('auth:passwordreset.set-a-new-password',{defaultValue:'Set a new password'})}</PageHeader>
            <ResetForm {...props} />
          </div>
        </Grid>
      </div>
    </ErrorBoundary>
  );
}

export default ResetPage;