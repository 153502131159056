export const VALID = {
  // eslint-disable-next-line no-useless-escape
  USERNAME_PREGMATCH : /^[a-z0-9_\-]{6,}$/i,  // copied from API.
  // eslint-disable-next-line no-useless-escape
  EMAIL_PREGMATCH    : /^(([^<>()\[\]\\.,;:\s@\"]+(\.[^<>()\[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,  // copied from API.
  // eslint-disable-next-line no-useless-escape
  PASSWORD_PREGMATCH : /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#\$€£%&'\(\)\*\+,\-\.\/:;<=>\?@[\\\]\^_`\{\|\}~])\S{8,250}$/u ,  // copied from API
  REDIRECTURL_PREGMATCH : /^\/app(.+)$/u ,  // begint met "/app"
};

export const LANG = {
  AUTO : 'auto',
  KEY : 'key',  // debug 'cimode'
  EN  : 'en',
  FR  : 'fr',
  NL  : 'nl',
}

export const DEFAULTUSER = {
  // id not existent is used to detect new user in validation
  projectid        : null, // overwrite this, its not in API URL
  enabled          : false,
  username         : '',
  email            : '',
  password         : '',
  //defaultprojectid : '',
  redirecturl      : '',
  locale_language  : LANG.AUTO,
}
