// @formatter:off
const USERS_RESET          = "USERS_RESET";
const USERS_LOAD_PENDING   = "USERS_LOAD_PENDING";
const USERS_LOAD_SUCCESS   = "USERS_LOAD_SUCCESS";
const USERS_LOAD_FAILURE   = "USERS_LOAD_FAILURE";
const USERS_ADD_PENDING    = "USERS_ADD_PENDING";
const USERS_ADD_SUCCESS    = "USERS_ADD_SUCCESS";
const USERS_ADD_FAILURE    = "USERS_ADD_FAILURE";
const USERS_REMOVE_PENDING = "USERS_REMOVE_PENDING";
const USERS_REMOVE_SUCCESS = "USERS_REMOVE_SUCCESS";
const USERS_REMOVE_FAILURE = "USERS_REMOVE_FAILURE";
const USERS_UPDATE_PENDING = "USERS_UPDATE_PENDING";
const USERS_UPDATE_SUCCESS = "USERS_UPDATE_SUCCESS";
const USERS_UPDATE_FAILURE = "USERS_UPDATE_FAILURE";
const USERS_GET_PENDING    = "USERS_GET_PENDING";
const USERS_GET_SUCCESS    = "USERS_GET_SUCCESS";
const USERS_GET_FAILURE    = "USERS_GET_FAILURE";

export default {
  USERS_RESET,
  USERS_LOAD_PENDING,
  USERS_LOAD_SUCCESS,
  USERS_LOAD_FAILURE,
  USERS_ADD_PENDING,
  USERS_ADD_SUCCESS,
  USERS_ADD_FAILURE,
  USERS_REMOVE_PENDING,
  USERS_REMOVE_SUCCESS,
  USERS_REMOVE_FAILURE,
  USERS_UPDATE_PENDING,
  USERS_UPDATE_SUCCESS,
  USERS_UPDATE_FAILURE,
  USERS_GET_PENDING,
  USERS_GET_SUCCESS,
  USERS_GET_FAILURE,
};
