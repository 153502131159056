import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import thunk from "redux-thunk";
import * as reducers from "./ducks"; // import all reducers from ducks/index.js

// For code splitting (bundles) see below
function createReducer(asyncReducers) {
  return combineReducers({
    ...reducers,
    ...asyncReducers
  })
}

export default function configureStore(initialState = {}) {
  const rootReducer = combineReducers(reducers);

  // Dont crash if browser doesnt have plugin.
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  // Add middleware in specific order. You can move the logger in development to see intermediate actions/state
  const middlewares = [];
  if (process.env.NODE_ENV === `development`) {
    console.info('(dit is een DEBUG build)')
    middlewares.push(thunk);

    //const { logger } = require(`redux-logger`);
    //middlewares.push(logger);

    const immutableStateInvariantMiddleware = require(`redux-immutable-state-invariant`).default();
    middlewares.push(immutableStateInvariantMiddleware);
  } else {
    middlewares.push(thunk);
  }

  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(
      applyMiddleware(...middlewares)
    )
  );

  // For code splitting of the Contracts state code.
  // See https://redux.js.org/recipes/code-splitting
  //
  // Add a dictionary to keep track of the registered async reducers
  store.asyncReducers = {}
  //
  // Create an inject reducer function
  // This function adds the async reducer, and creates a new combined reducer
  store.injectReducer = (key, asyncReducer) => {
    store.asyncReducers[key] = asyncReducer
    store.replaceReducer(createReducer(store.asyncReducers))
  }
  // End code splitting.

  return store;
}
