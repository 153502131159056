import { combineReducers } from "redux";
import { normalize } from 'normalizr';
import types from "./types";
import schemas from "./schemas";

// State shape
export const initialState = {
  auth        : {
    authenticated: false,
    token        : '',
    projectsById : {},
    projectIds   : [],
  },
  user        : {
    username: '',
  },
  current     : {
    projectid: null,
  },
  apicomstatus: {
    pending: false,
    error  : null,
  },
};

const auth = (state = initialState.auth, action) => {
  switch (action.type) {
    case types.SESSION_SIGNIN_SUCCESS:
    case types.SESSION_REFRESH_SUCCESS:
      const normalized = normalize(action.payload.auth.userprojects, schemas.projectsSchema);

      // We dont need everything the API returns.
      let projectsSimpler = {};
      Object.keys(normalized.entities.projects).forEach(id => {
        projectsSimpler[id] = {
          id                : normalized.entities.projects[id].id,
          name              : normalized.entities.projects[id].name,
          scopes            : normalized.entities.projects[id].scopes,              // Permissions for users
          features          : normalized.entities.projects[id].features,            // Enable flash etc.
          parameters        : normalized.entities.projects[id].parameters,          // Project-level settings (used for QLK/MLNsafetysign)
          player_restriction: normalized.entities.projects[id].player_restriction,  // Project properties
          projects          : normalized.entities.projects[id].projects,
          theme             : normalized.entities.projects[id].theme,               // GUI theme
        };
      });

      return {
        authenticated: action.payload.auth.authenticated,
        token        : action.payload.auth.token,
        projectsById : projectsSimpler,
        projectIds   : normalized.result,
      };
    case types.SESSION_SIGNIN_FAILURE:
    case types.SESSION_SIGNOUT_SUCCESS: // and we assume action.payload.auth.authenticated===false
    case types.SESSION_SIGNOUT_FAILURE:
    case types.SESSION_REFRESH_FAILURE: // todo Be less fatal to a momentary loss of internet
    case types.SESSION_RESET:
      return { ...initialState.auth };
    default:
      return state;
  }
};

const user = (state = initialState.user, action) => {
  switch (action.type) {
    case types.SESSION_SIGNIN_SUCCESS:
    case types.SESSION_REFRESH_SUCCESS:
      return { ...action.payload.user };
    case types.SESSION_SIGNIN_FAILURE:
    case types.SESSION_SIGNOUT_SUCCESS:
    case types.SESSION_SIGNOUT_FAILURE:
    case types.SESSION_REFRESH_FAILURE:
    case types.SESSION_RESET:
      return { ...initialState.user };
    default:
      return state;
  }
};

const current = (state = initialState.current, action) => {
  switch (action.type) {
    case types.SESSION_CURRENT:
      return {
        ...state,
        ...action.payload, // may contain property projectid
      };
    case types.SESSION_SIGNIN_SUCCESS:
      return {
        ...state,
        projectid: action.payload.user.defaultprojectid || action.payload.user.projectid
      };
    case types.SESSION_SIGNIN_FAILURE:
    case types.SESSION_SIGNOUT_SUCCESS:
    case types.SESSION_SIGNOUT_FAILURE:
    case types.SESSION_REFRESH_FAILURE:
    case types.SESSION_RESET:
      return { ...initialState.current };
    default:
      return state;
  }
};

const apicomstatus = (state = initialState.apicomstatus, action) => {
  switch (action.type) {
    case types.SESSION_SIGNIN_PENDING:
    case types.SESSION_SIGNOUT_PENDING:
    case types.SESSION_REFRESH_PENDING:
    case types.SESSION_PASSWORDFORGOT_PENDING:
    case types.SESSION_PASSWORDRESET_PENDING:
      return {
        ...state,
        pending: true,
      };
    case types.SESSION_SIGNIN_SUCCESS:
    case types.SESSION_SIGNOUT_SUCCESS:
    case types.SESSION_REFRESH_SUCCESS:
    case types.SESSION_PASSWORDFORGOT_SUCCESS:
    case types.SESSION_PASSWORDRESET_SUCCESS:
      return {
        ...state,
        pending: false,
        error  : null,
      };
    case types.SESSION_SIGNIN_FAILURE:
    case types.SESSION_SIGNOUT_FAILURE:
    case types.SESSION_REFRESH_FAILURE:
    case types.SESSION_PASSWORDFORGOT_FAILURE:
    case types.SESSION_PASSWORDRESET_FAILURE:
      return {
        ...state,
        pending: false,
        error  : action.payload.error,
      };
    case types.SESSION_RESET:
      return { ...initialState.apicomstatus };
    default:
      return state;
  }
};

const sessionReducer = combineReducers({
  auth,
  user,
  current,
  apicomstatus,
});

export default sessionReducer;
