import { schema } from 'normalizr';

const userSchema = new schema.Entity('users');

const usersSchema = new schema.Array(userSchema);

export default {
  userSchema,
  usersSchema,
};
