import React from "react";
import PropTypes from 'prop-types';
import { MenuItem, Nav, Navbar, NavDropdown, NavItem } from 'react-bootstrap';
import { LinkContainer, } from 'react-router-bootstrap';
import FontAwesome from 'react-fontawesome';
import { useTranslation } from "react-i18next";

import TreeIdent from "../common/TreeIdent";
import { MODULESUPPORT } from "logic/constants"
import CONFIG from "config/config";
import { PLAYERTYPE } from "logic/player/constants"


const NavbarMenu = ({
                      projectid, authenticated, projectname, projects, user,
                      onRefresh, onSignout, onClickProject,
                      history,
                      projectclaims : { scopes, player_restriction:{ playertypeid }, }
                    }) => {
  const { t } = useTranslation();

  const link = {
    players   : (
      <LinkContainer to={"/projects/" + projectid + "/displays"} key={1001}>
        <NavItem eventKey={'displays'}>
          <FontAwesome name="television"/>
          {' '}
          <span className="hidden-sm">{t('common:displays',{defaultValue:'Displays'})}</span>
        </NavItem>
      </LinkContainer>
    ),
    libraries : (
      <LinkContainer to={"/projects/" + projectid + "/libraries"} key={1002}>
        <NavItem eventKey={'libraries'}>
          <FontAwesome name="book"/>
          {' '}
          <span className="hidden-sm">{t('common:libraries',{defaultValue:'Libraries'})}</span>
        </NavItem>
      </LinkContainer>
    ),
    templates : (
      <LinkContainer to={"/projects/" + projectid + "/templates"} key={1003}>
        <NavItem eventKey={'templates'}>
          <FontAwesome name="object-group"/>
          {' '}
          <span className="hidden-sm">{t('common:templates',{defaultValue:'Templates'})}</span>
        </NavItem>
      </LinkContainer>
    ),
    playlists : (
      <LinkContainer to={"/projects/" + projectid + "/playlists"} key={1004}>
        <NavItem eventKey={'playlists'}>
          <FontAwesome name="film"/>
          {' '}
          <span className="hidden-sm">{t('common:playlists',{defaultValue:'Playlists'})}</span>
        </NavItem>
      </LinkContainer>
    ),
    schedules : (
      <LinkContainer to={"/projects/" + projectid + "/schedules"} key={1005}>
        <NavItem eventKey={'schedules'}>
          <FontAwesome name="calendar"/>
          {' '}
          <span className="hidden-sm">{t('common:schedules',{defaultValue:'Schedules'})}</span>
        </NavItem>
      </LinkContainer>
    ),
    scenarios : (
      <LinkContainer to={"/projects/" + projectid + "/scenarios"} key={1006}>
        <NavItem eventKey={'scenarios'}>
          <FontAwesome name="bullhorn"/>
          {' '}
          <span className="hidden-sm">{t('common:scenarios',{defaultValue:'Scenarios'})}</span>
        </NavItem>
      </LinkContainer>
    ),
    users : (
      <LinkContainer to={"/projects/" + projectid + "/users"} key={1007}>
        <NavItem eventKey={'users'}>
          <FontAwesome name="user"/>
          {' '}
          <span className="hidden-sm">{t('common:users',{defaultValue:'Users'})}</span>
        </NavItem>
      </LinkContainer>
    ),
    projects : (
      <LinkContainer to={"/projects/" + projectid + "/projects"} key={1008}>
        <NavItem eventKey={'projects'}>
          <FontAwesome name="briefcase"/>
          {' '}
          <span className="hidden-sm">{t('common:subprojects',{defaultValue:'Sub-projects'})}</span>
        </NavItem>
      </LinkContainer>
    )
  };

  const qlkview = playertypeid === PLAYERTYPE.QLK;

  const show = {
    players   : !qlkview && (scopes.players.read),
    libraries : !qlkview && (scopes.libraries.read && MODULESUPPORT.LIBRARIES.includes(playertypeid)),
    templates : !qlkview && (scopes.templates.read && MODULESUPPORT.TEMPLATES.includes(playertypeid)),
    playlists : !qlkview && (scopes.playlists.read && MODULESUPPORT.PLAYLISTS.includes(playertypeid)),
    schedules : !qlkview && (scopes.schedules.read && MODULESUPPORT.SCHEDULES.includes(playertypeid)),
    scenarios : !qlkview && (scopes.scenarios.read && MODULESUPPORT.SCENARIOS.includes(playertypeid)),
    users     : !qlkview && (scopes.users.read),
    projects  : false, // never shown yet
  };
  const showAnything = (
    show.players   ||
    show.libraries ||
    show.templates ||
    show.playlists ||
    show.schedules ||
    show.scenarios ||
    show.users     ||
    show.projects
  );

  // Console menu for hidden/sysops pages
  let consoleMenuitems = [];
  if (scopes.contracts && scopes.contracts.sysops) {
    consoleMenuitems.push(<MenuItem header key={1}>Q-lite SysOps:</MenuItem>);
    consoleMenuitems.push(
      <LinkContainer to={"/contracts/"} key={2}>
        <MenuItem>
          <FontAwesome name="gavel"/>
          {' '}
          {'Contracts'}
        </MenuItem>
      </LinkContainer>
    );
    consoleMenuitems.push(
      <MenuItem divider key={3}/>
    );
  }
  if (scopes.users.sysops) {
    // todo add /users link
  }
  // Add links to hidden project resource pages
  let consoleHidden = [];
  if (!show.players   && scopes.players.sysops  ) consoleHidden.push(link.players  );
  if (!show.libraries && scopes.libraries.sysops) consoleHidden.push(link.libraries);
  if (!show.templates && scopes.templates.sysops) consoleHidden.push(link.templates);
  if (!show.playlists && scopes.playlists.sysops) consoleHidden.push(link.playlists);
  if (!show.schedules && scopes.schedules.sysops) consoleHidden.push(link.schedules);
  if (!show.scenarios && scopes.scenarios.sysops) consoleHidden.push(link.scenarios);
  if (!show.users     && scopes.users.sysops    ) consoleHidden.push(link.users    );
  if (!show.projects  && scopes.projects.sysops ) consoleHidden.push(link.projects );
  if (consoleHidden.length > 0) {
    consoleMenuitems.push(<MenuItem header key={4}>Hidden project pages:</MenuItem>);
    consoleMenuitems = consoleMenuitems.concat(consoleHidden);
    consoleMenuitems.push(<MenuItem divider key={5}/>);
  }


  return (
    <React.Fragment>
      <Navbar.Collapse>
        {showAnything ? (
        <Nav>
          {show.players   ? link.players   : null}
          {show.libraries ? link.libraries : null}
          {show.templates ? link.templates : null}
          {show.playlists ? link.playlists : null}
          {show.schedules ? link.schedules : null}
          {show.scenarios ? link.scenarios : null}
          {show.users     ? link.users     : null}
          {show.projects  ? link.projects  : null}
        </Nav>
        ) : null}

        <Nav pullRight>
          {/* Dropdowns not wrapped in component/container so Navbar's collapseOnSelect can function correctly. */}

          <NavDropdown
            id="nav-project-dropdown"
            className="mainmenuProjectsDropdown"
            disabled={!authenticated}
            onSelect={eventKey => {
              if (eventKey === null) return; // let linkcontainer handle this
              const projectid = eventKey;
              // Update state (optimistic)
              onClickProject(projectid);
              // Redirect user to (same?) page in that project:
              history.push("/projects/" + projectid); // todo use existing URL but with new projectid
              // ^ Note that AutoLoadingLibraryitemsPage and AutoLoadingPlayerPage can redirect to their list automatically.
              //   This can only be seen if you disable the line above.
            }}
            title={
              <span>
                <FontAwesome name="briefcase"/>
                {' '}
                <span className="hidden-sm">{projectname ? projectname : '?'}</span>
              </span>
            }
          >
            {scopes.projects.read ? (
              <React.Fragment>
                <LinkContainer to="/projects">
                  <MenuItem eventKey={null}>
                    <FontAwesome name="cog"/>
                    {' '}
                    {t('common:mainmenu.manage-my-projects',{defaultValue:'Manage my projects'})}
                  </MenuItem>
                </LinkContainer>

                <MenuItem divider/>
              </React.Fragment>
            ) : null}

            {projects.map(p => (
              <MenuItem key={p.id} eventKey={p.id} active={p.id === projectid}>
                <TreeIdent depth={p.parentprojectids.length} />
                {p.name}
              </MenuItem>
            ))}
          </NavDropdown>

          {consoleMenuitems && (consoleMenuitems.length > 0) ? (
            <NavDropdown
              id="nav-sysops-dropdown"
              title={
                <span>
                  <FontAwesome name="cloud"/>
                  {' '}
                  <span className="hidden-sm">Console</span>
                </span>
              }
            >
              {consoleMenuitems}
            </NavDropdown>
          ) : null}

          {user && user.id && user.username ? (
            <NavDropdown
              id="nav-user-dropdown"
              disabled={!authenticated}
              title={
                <span>
                  <FontAwesome name="user"/>
                  {' '}
                  <span className="hidden-sm">{user.username}</span>
                </span>
              }
            >
              <LinkContainer to={"/projects/" + projectid + "/users/" + user.id}>
                <MenuItem>
                  <FontAwesome name="user"/>
                  {' '}
                  {t('common:mainmenu.profile',{defaultValue:'Profile'})}
                </MenuItem>
              </LinkContainer>

              <MenuItem divider/>

              {scopes.users.sysops || CONFIG.debug ? (
                <MenuItem onClick={() => {onRefresh()}}>(refresh session)</MenuItem>
              ) : null}

              <MenuItem onClick={() => {onSignout()}}>
                <FontAwesome name="sign-out"/>
                {' '}
                {t('common:mainmenu.sign-out',{defaultValue:'Sign out'})}
              </MenuItem>

            </NavDropdown>
          ) : null}

        </Nav>
      </Navbar.Collapse>
    </React.Fragment>
  )
}

NavbarMenu.propTypes = {
  authenticated : PropTypes.bool,
  user      : PropTypes.shape({
    id       : PropTypes.string.isRequired,
    username : PropTypes.string.isRequired,
  }),
  onSignout     : PropTypes.func.isRequired,
  onRefresh     : PropTypes.func.isRequired,
  projectid     : PropTypes.string.isRequired,
  projectname   : PropTypes.string,
  projects      : PropTypes.arrayOf(
    PropTypes.shape({
      id  : PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  onClickProject: PropTypes.func.isRequired,
  history       : PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  projectclaims : PropTypes.shape({
    scopes : PropTypes.shape({
      players  : PropTypes.shape({ read : PropTypes.bool, }),
      libraries: PropTypes.shape({ read : PropTypes.bool, }),
      templates: PropTypes.shape({ read : PropTypes.bool, }),
      playlists: PropTypes.shape({ read : PropTypes.bool, }),
      schedules: PropTypes.shape({ read : PropTypes.bool, }),
      users    : PropTypes.shape({ read : PropTypes.bool, sysops : PropTypes.bool, }),
      projects : PropTypes.shape({ sysops : PropTypes.bool, }),
    }).isRequired,  // and more
    player_restriction : PropTypes.shape({
      playertypeid: PropTypes.string,
    }).isRequired,  // and more
  }).isRequired,
};

export default NavbarMenu;