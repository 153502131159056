import React from "react";
import { useTranslation } from "react-i18next";

import { version } from "logic/appmeta";


const style = {
  version : {
    //backgroundColor:"red",
    color:"gray",
    fontSize:"11px",
    textAlign:"right",
    fontFamily:"monospace",
    bottom: "0px",
    position: "fixed",
    width:"100%",
  },
}

const AppVersion = () => {
  const { t } = useTranslation();
  return(
    <div style={style.version}>
      {t('auth:version', { defaultValue: 'Version: {{version}}', version: version })}
    </div>
  )
}

export default AppVersion;