import React from "react";
import { Grid, Row, Col, PageHeader, } from "react-bootstrap";
import FontAwesome from "react-fontawesome";
import { useTranslation } from "react-i18next";

const Loading = () => {
  const { t } = useTranslation();

  // The grid is an attempt to center it.
  return (
    <Grid fluid>
      <PageHeader>
        {t('common:loading', { defaultValue: 'Loading…' })}
      </PageHeader>
      {/* try to center spinner */}
      <Row>
        <Col xs={1} md={4}></Col>
        <Col xs={4} md={4}>
          <FontAwesome name={'spinner'} size={'4x'} pulse />
        </Col>
        <Col xs={1} md={4}></Col>
      </Row>
    </Grid>
  );
}

export default Loading;