import { combineReducers } from "redux";
import { normalize } from 'normalizr';
import types from "./types";
// import typesSession from "../session/types";
import schemas from "./schemas";

// State shape
export const initialState = {
  list        : {
    byId          : {},
    idsByProjectId: {},
    ids           : [], // all
  },
  apicomstatus: {
    pending: false,
    error  : null,
  },
};

const list = (state = initialState.list, action) => {
  switch (action.type) {
    case types.USERS_RESET:
      return { ...initialState.list };
    case types.USERS_LOAD_SUCCESS:
      const normalized = normalize(action.payload.users, schemas.usersSchema);
      return {
        ...state,
        byId          : {
          ...state.byId,
          ...normalized.entities.users
        },
        idsByProjectId: {
          ...state.idsByProjectId,
          [action.payload.projectid]: normalized.result
        },
        ids           : [
          //...state.ids,
          ...normalized.result,
        ],
      };
    case types.USERS_ADD_SUCCESS:
    case types.USERS_UPDATE_SUCCESS:
    case types.USERS_GET_SUCCESS:
      const normalized2 = normalize(action.payload.user, schemas.userSchema);
      return {
        ...state,
        byId          : {
          ...state.byId,
          ...normalized2.entities.users
        },
        idsByProjectId: {
          ...state.idsByProjectId,
          [action.payload.projectid]: [
            ...(state.idsByProjectId[action.payload.projectid] || []).filter(id => (id !== action.payload.userid)),
            normalized2.result
          ],
        },
        ids           : [
          ...state.ids.filter(id => (id !== action.payload.userid)),
          normalized2.result
        ],
      };
    case types.USERS_REMOVE_SUCCESS:
      return {
        ...state,
        byId          : {                     // mark as deleted (not needed but practical for debug)
          ...state.byId,
          [action.payload.userid]: {
            ...state.byId[action.payload.userid],
            __deleted: true,
          },
        },
        idsByProjectId: {            // remove references to userid
          ...state.idsByProjectId,
          [action.payload.projectid]: (state.idsByProjectId[action.payload.projectid] || []).filter(id => (id !== action.payload.userid)),
        },
        ids           : [
          ...state.ids.filter(id => (id !== action.payload.userid))
        ],
      };
    // Disabled because we'll handle this with less code in the session operation for now, it directly calls the ADD_SUCCESS above.
    // case typesSession.SESSION_SIGNIN_SUCCESS:
    //   const normalized3 = normalize(action.payload.user, schemas.userSchema);
    //   return {
    //     ...state,
    //     byId          : {
    //       ...state.byId,
    //       ...normalized3.entities.users
    //     },
    //     idsByProjectId: {
    //       ...state.idsByProjectId,
    //       [action.payload.user.projectid]: [
    //         ...(state.idsByProjectId[action.payload.user.projectid] || []).filter(id => (id !== action.payload.user.id)),
    //         normalized3.result
    //       ],
    //     },
    //   };

    // todo also delete when error 404 Not Found ?
    default:
      return state;
  }
};

const apicomstatus = (state = initialState.apicomstatus, action) => {
  switch (action.type) {
    case types.USERS_LOAD_PENDING:
    case types.USERS_ADD_PENDING:
    case types.USERS_REMOVE_PENDING:
    case types.USERS_UPDATE_PENDING:
    case types.USERS_GET_PENDING:
      return {
        ...state,
        pending: true,
      };
    case types.USERS_LOAD_SUCCESS:
    case types.USERS_ADD_SUCCESS:
    case types.USERS_REMOVE_SUCCESS:
    case types.USERS_UPDATE_SUCCESS:
    case types.USERS_GET_SUCCESS:
      return {
        ...state,
        pending: false,
        error  : null,
      };
    case types.USERS_LOAD_FAILURE:
    case types.USERS_ADD_FAILURE:
    case types.USERS_REMOVE_FAILURE:
    case types.USERS_UPDATE_FAILURE:
    case types.USERS_GET_FAILURE:
      return {
        ...state,
        pending: false,
        error  : action.payload.error,
      };
    case types.USERS_RESET:
      return { ...initialState.apicomstatus };
    default:
      return state;
  }
};

const usersReducer = combineReducers({
  list,
  apicomstatus,
});

export default usersReducer;
