import React from "react";
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { Form, FormGroup, FormControl, Button } from 'react-bootstrap';
import FontAwesome from 'react-fontawesome';
import { withTranslation } from "react-i18next";

import Oopsy from "../../common/Oopsy";
import ChangeLanguageButton from "../../common/ChangeLanguageButton";
import { TABS } from "./index";


class SigninForm extends React.Component {
  constructor(props) {
    super(props);
    this.refUsernameInput = null;
    this.refPasswordInput = null;
  }

  componentDidMount() {
    if (this.refUsernameInput) this.refUsernameInput.focus();
  }

  render() {
    const { onTab, onSignin, onSignout, authenticated, user, apicomstatus, /*projectclaims,*/ location, t, } = this.props;

    return !authenticated ? (
      <div>
        <Form>
          <Oopsy apicomstatus={apicomstatus} />
          <FormGroup>
            <FormControl
              type="text"
              inputRef={input => {this.refUsernameInput = input}}
              name="username"
              placeholder={t('auth:username-placeholder',{defaultValue:'Username'})}
              required
            />
          </FormGroup>
          <FormGroup>
            <FormControl
              type="password"
              inputRef={input => {this.refPasswordInput = input}}
              name="password"
              placeholder={t('auth:password-placeholder',{defaultValue:'Password'})}
              required
            />
          </FormGroup>
          <Button type="submit" bsSize="large" bsStyle="primary" block onClick={e => {
            e.preventDefault();
            const username = this.refUsernameInput.value.trim();
            const password = this.refPasswordInput.value.trim();
            onSignin(username, password);
          }}>
            <FontAwesome name="sign-in"/>
            {' '}
            {t('auth:sign-in',{defaultValue:'Sign in'})}
          </Button>
        </Form>
        <Button
          bsStyle="link"
          onClick={(e) => {onTab(TABS.FORGOT)}}
        >
          {t('auth:forgot-your-password-link',{defaultValue:'Forgot your password?'})}
        </Button>
        <ChangeLanguageButton/>
        {/*<pre>{JSON.stringify(location.state.fromLocation)}</pre>*/}
      </div>
    ) : (
      <div>
        <p>
          {/* todo i18n-JSX issue #349 <strong>{{username}}</strong> */}
          {t('auth:signed-in-as',{
            defaultValue:'Signed in as {{username}}',
            username: user.username,
          })}
        </p>
        <Button
          bsSize="large"
          onClick={e => {onSignout()}}
        >
          <FontAwesome name="sign-out"/>
          {' '}
          {t('auth:sign-out',{defaultValue:'Sign out'})}
        </Button>

        {/* Login gelukt, stuur gebruiker naar pagina */}
        {location.state && location.state.fromLocation
          ? <Redirect to={location.state.fromLocation}/>                 // terug naar pagina die om login vroeg
          : user.defaultpage
            ? <Redirect to={user.defaultpage.replace(/^\/app/g, '')} />  // user startpage zonder '/app' prefix
            : <Redirect to={'/projects/'+user.projectid} />              // standaard
        }

        {/*
        <pre>apicomstatus={JSON.stringify(apicomstatus, null, 2)}</pre>
        <pre>projectclaims={JSON.stringify(projectclaims, null, 2)}</pre>
        <pre>location.state={JSON.stringify(location.state, null, 0)}</pre>
        <pre>user={JSON.stringify(user, null, 2)}</pre>
        */}
      </div>
    );
  }
};

SigninForm.propTypes = {
  apicomstatus: PropTypes.object.isRequired,
  onTab       : PropTypes.func.isRequired,
  onSignin    : PropTypes.func.isRequired,
  onSignout   : PropTypes.func.isRequired,
  t           : PropTypes.func.isRequired,
};

export default withTranslation()(SigninForm);
