import React from "react";
import PropTypes from "prop-types";
import FontAwesome from "react-fontawesome";


const style = {
  whiteSpace : 'nowrap',
}

const TreeIdent = ({depth}) => {
  let d = depth;
  if (!d || (d===0)) return null;
  return (
    <span style={style}>
      {d > 2
        ? [...Array(d-2)].map((e,i)=>(<FontAwesome key={i} name="" fixedWidth={true}/>))
        : null}
      {d > 1
        ? <FontAwesome name="level-up fa-rotate-90" fixedWidth={true}/>
        : null}
    </span>
  )
}

TreeIdent.propTypes = {
  depth: PropTypes.number.isRequired,
}

export default TreeIdent;