import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

const AuthWrap = ({ authenticated, children, location }) => (
  authenticated
    ? children
    : <Redirect to={{ pathname: "/signin", state: { fromLocation: location } }}/>
);

AuthWrap.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  children     : PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default AuthWrap;
