import { createSelector } from 'reselect';
import constants from './constants';
//import { arrayCompareStrict } from "logic/js-misc/arrays";


const getAuthenticated = (state) => {
  return state.sessionState.auth.authenticated;
};

const getToken = (state) => {
  return state.sessionState.auth.token;
};

const getTokenClaims = (state) => {
  const token = getToken(state);
  try {
    // Parse
    const jwt = token.split('.');
    if (jwt.length !== 3) throw new Error('API Token unknown format');
    const header = JSON.parse(atob(jwt[0]));
    const claims = JSON.parse(atob(jwt[1]));
    const signature = jwt[2];

    // Verify
    if (!header || header.typ !== 'JWT') throw new Error('API Token unexpected header');
    if (!claims || !claims.user || !claims.user.id) throw new Error('API Token missing claims');
    if (!signature) throw new Error('API Token invalid signature'); // todo verify using public key

    return claims;
  } catch (e) {
    console.error(e);
  }
  return null;
};

const getUser = (state) => {
  return state.sessionState.user;
};

const getProjectClaims = (state, projectid) => {
  if (
    projectid &&
    state.sessionState.auth.projectsById.hasOwnProperty(projectid)
  ) {
    return {
      projectid,
      scopes             : state.sessionState.auth.projectsById[projectid].scopes,
      features           : state.sessionState.auth.projectsById[projectid].features,
      player_restriction : state.sessionState.auth.projectsById[projectid].player_restriction,
      parameters         : state.sessionState.auth.projectsById[projectid].parameters,
    }
  }
  return {
    projectid          : null,
    scopes             : constants.defaultScopes,
    features           : constants.defaultFeatures,
    player_restriction : constants.defaultPlayerRestriction,
    parameters         : constants.defaultParameters,
  };
};

const getProjectClaimsMemoized = createSelector(
  (state) => state.sessionState.auth.projectsById,
  (state, projectid) => projectid,
  (projectsById, projectid) => getProjectClaims({sessionState:{ auth:{ projectsById } }}, projectid)
);

const getCurrentProjectid = (state) => {
  return state.sessionState.current.projectid;
};

const getProjectsflat = (state) => {
  const appendProjects = (projectIds, projectsById, parentprojectids, outputArray) => {
    if (projectIds) {
      projectIds.forEach(id => {
        const project = {
          ...projectsById[id],
          parentprojectids: [...parentprojectids, id],
        };
        outputArray.push(project);
        appendProjects(projectsById[id].projects, projectsById, project.parentprojectids, outputArray);
      })
    }
  };

  let list = [];
  appendProjects(state.sessionState.auth.projectIds, state.sessionState.auth.projectsById, [], list);
  return list;

  // When showing this to users sorted (e.g. by name) afterwards fix tree-view using:
  //return list.sort((a, b) => arrayCompareStrict(a.parentprojectids, b.parentprojectids));
};

const getProjectsflatMemoized = createSelector(
  (state) => state.sessionState.auth.projectIds,
  (state) => state.sessionState.auth.projectsById,
  (projectIds, projectsById) => getProjectsflat({sessionState:{ auth: { projectIds, projectsById } }})
);

const getApicomstatus = (state) => {
  return state.sessionState.apicomstatus;
};

export default {
  getAuthenticated,
  getToken,
  getTokenClaims,
  getUser,
  getProjectClaims : getProjectClaimsMemoized,
  getCurrentProjectid,
  getProjectsflat: getProjectsflatMemoized,
  getApicomstatus,
};
