import i18n from "i18next";
import { initReactI18next } from "react-i18next";

//import lang_en_default from "views/resources/translations/en/default.json";
import lang_en_auth      from "views/resources/translations/en/auth.json";
import lang_en_common    from "views/resources/translations/en/common.json";
import lang_en_players   from "views/resources/translations/en/players.json";
import lang_en_libraries from "views/resources/translations/en/libraries.json";
import lang_en_pagetypes from "views/resources/translations/en/pagetypes.json";
import lang_en_playlists from "views/resources/translations/en/playlists.json";
import lang_en_projects  from "views/resources/translations/en/projects.json";
import lang_en_scenarios from "views/resources/translations/en/scenarios.json";
import lang_en_schedules from "views/resources/translations/en/schedules.json";
import lang_en_templates from "views/resources/translations/en/templates.json";
import lang_en_users     from "views/resources/translations/en/users.json";

import lang_nl_auth      from "views/resources/translations/nl/auth.json";
import lang_nl_common    from "views/resources/translations/nl/common.json";
import lang_nl_players   from "views/resources/translations/nl/players.json";
import lang_nl_libraries from "views/resources/translations/nl/libraries.json";
import lang_nl_pagetypes from "views/resources/translations/nl/pagetypes.json";
import lang_nl_playlists from "views/resources/translations/nl/playlists.json";
import lang_nl_projects  from "views/resources/translations/nl/projects.json";
import lang_nl_scenarios from "views/resources/translations/nl/scenarios.json";
import lang_nl_schedules from "views/resources/translations/nl/schedules.json";
import lang_nl_templates from "views/resources/translations/nl/templates.json";
import lang_nl_users     from "views/resources/translations/nl/users.json";

import lang_fr_auth      from "views/resources/translations/fr/auth.json";
import lang_fr_common    from "views/resources/translations/fr/common.json";
import lang_fr_players   from "views/resources/translations/fr/players.json";
import lang_fr_libraries from "views/resources/translations/fr/libraries.json";
import lang_fr_pagetypes from "views/resources/translations/fr/pagetypes.json";
import lang_fr_playlists from "views/resources/translations/fr/playlists.json";
import lang_fr_projects  from "views/resources/translations/fr/projects.json";
import lang_fr_scenarios from "views/resources/translations/fr/scenarios.json";
import lang_fr_schedules from "views/resources/translations/fr/schedules.json";
import lang_fr_templates from "views/resources/translations/fr/templates.json";
import lang_fr_users     from "views/resources/translations/fr/users.json";


// the translations
const resources = {
  // de: deze ontbreekt bewust als TEST
  en: {
    //default   : lang_en_default,
    auth      : lang_en_auth,
    common    : lang_en_common,
    libraries : lang_en_libraries,
    pagetypes : lang_en_pagetypes,
    players   : lang_en_players,
    playlists : lang_en_playlists,
    projects  : lang_en_projects,
    scenarios : lang_en_scenarios,
    schedules : lang_en_schedules,
    templates : lang_en_templates,
    users     : lang_en_users,
  },
  nl: {
    auth      : lang_nl_auth,
    common    : lang_nl_common,
    libraries : lang_nl_libraries,
    pagetypes : lang_nl_pagetypes,
    players   : lang_nl_players,
    playlists : lang_nl_playlists,
    projects  : lang_nl_projects,
    scenarios : lang_nl_scenarios,
    schedules : lang_nl_schedules,
    templates : lang_nl_templates,
    users     : lang_nl_users,
  },
  fr: {
    auth      : lang_fr_auth,
    common    : lang_fr_common,
    libraries : lang_fr_libraries,
    pagetypes : lang_fr_pagetypes,
    players   : lang_fr_players,
    playlists : lang_fr_playlists,
    projects  : lang_fr_projects,
    scenarios : lang_fr_scenarios,
    schedules : lang_fr_schedules,
    templates : lang_fr_templates,
    users     : lang_fr_users,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({                // Keep options in sync between here and i18next-scanner.config.js
    resources,
    lng: "en",
    fallbackLng: "en",
    returnEmptyString: false,

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;


// Code above from https://react.i18next.com/guides/quick-start
//
// The interesting part here is by i18n.use(initReactI18next) we pass the i18n instance to react-i18next
// which will make it available for all the components via the context api.


// Vertaling inbouwen doen we zo:
//
// Vervang       <span>Remove this display?</span>
// door          <span>{t('players:remove.question',{defaultValue:'Remove this display?'})}</span>
//
//
// - functional components       Hooks    `const { t } = useTranslation();`
// - class components            HOC      `default export withTranslation()(FooBar);` en vergeet niet de `t : PropTypes.func.isRequired,`
// - containers                  NEVER    gebruik componenten
//
// Het is mogelijk om een class de `t` mee te laten geven via een prop aan een functioneel component,
// maar eigenlijk overbodig omdat Hooks minder haken en ogen heeft. ¯\_(ツ)_/¯


// Taal wijzigen kan in componenten dus met `props.i18n.changeLanguage` maar ook erbuiten als die de `i18n` instantie uit deze file import.