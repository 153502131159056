import { schema } from 'normalizr';

const projectSchema = new schema.Entity('projects');
projectSchema.define({
  projects: [projectSchema]
});

const projectsSchema = new schema.Array(projectSchema);

export default {
  projectSchema,
  projectsSchema,
};
